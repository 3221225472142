import { request } from "../api/api";

export const getHeaderFooter = () => {
  return request.get("/setting");
};

export const getslider = () => {
  return request.get("/slider");
};

export const getSpecialties = () => {
  return request.get("/home_specialties");
};

export const getSpecialtiesList = (params) => {
  return request.get(
    `/specialties?per_page=${params.per_page}&page=${params.page}`
  );
};

export const getAboutSection = () => {
  return request.get("/about-section");
};

export const getHealthAspirations = () => {
  return request.get("/health_aspirations");
};

export const getVisionMission = () => {
  return request.get("/vision_mission");
};

export const getCoreValue = () => {
  return request.get("/core_value");
};

export const getOurCommitment = () => {
  return request.get("/our_commitment");
};

export const getQualityStandards = () => {
  return request.get("/quality_standards");
};

export const getContactInformation = () => {
  return request.get("/contact-information");
};

export const contactEmergency = (data) => {
    return request.post('/contact-emergency', data);
}

export const bookAppointment = (data) => {
  return request.post("/book_appointment", data);
};

export const getSpecialtyDetails = (id) => {
  return request.get(`/specialties-details/${id}`);
};

export const getHealthScreen = (params) => {
  return request.get(
    `/get_health_screening?per_page=${params.per_page}&page=${params.page}&search=${params.searchTerm}&package_id=${params.hospitals_id}&specialties_id=${params.specialties_id}`
  );
};

export const getHealthScreenInfo = (id) => {
  return request.get(`/get_health_screening_info/${id}`);
};

export const getHealthScreenPackage = () => {
  return request.get(`/get_health_screening_package`);
};
export const getDoctorList = (params) => {
    return request.get(`/doctor?per_page=${params.per_page}&page=${params.page}&search=${params.searchTerm}&hospitals_id=${params.hospitals_id}&specialties_id=${params.specialties_id}`)
}

export const getHospitalsList = () => {
    return request.get('/hospital');
}

export const getDoctorInfo = (id) => {
    return request.get(`/doctor_info/${id}`);
}

export const getDiseases = () => {
 return request.get('/diseases');
}

export const searchAPI = (searchTerm) => {
  return request.get(`/search?search=${searchTerm}`);
};

export const getSpecialistList = () => {
  return request.get('/specialties_name');
}

export const getCustomLink = (params) => {
  return request.get(`/custom_page`, params)
} 
export const getCustomAPIData = (params) => {
  return request.get(`/custom_page_detail`, params)
} 
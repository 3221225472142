import React from "react";
import "./Shimmer.css";

const Shimmer = ({ style }) => {
  return (
    <div className="shimmer-wrapper" style={style}>
      <div className="shimmer"></div>
    </div>
  );
};

export default Shimmer;

import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
  Grid2,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Pagination,
} from "@mui/material";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import { images, doctorImages, banner } from "../../Config/images";
import "../DoctorList/DoctorList.css";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import SearchBar from "material-ui-search-bar";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { getDoctorList, getHospitalsList, getSpecialistList } from "../../Services/services";
import Booking from "../../componant/Booking/Booking";
import { Skeleton } from "@mui/material";

const DoctorList = ({ headerData, footerData }) => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Doctor List",
      url: "/doctor-list",
    },
  ];
  const topRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [doctorsList, setDoctorsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [hospitalsList, setHospitalsList] = useState([]);
  const [specialties, setSpecialtiesList] = useState([]);
  // const [selectedHospitalId, setSelectedHospitalId] = useState("");
  const [specId, setSpecId] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const handleSearchChange = (event) => {
    console.log(event); // Check the event structure
    if (event && event.target) {
      setSearchTerm(event.target.value); // Ensure target exists
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      borderColor: "var(--secondary-color)",
      borderWidth: "1px",
      borderStyle: "solid",
      marginTop: "30px",
    },
    "& .Mui-selected": {
      borderColor: "var(--secondary-color)",
      backgroundColor: "var(--secondary-color)  !important",
      color: "var(--white)",
    },
  }));

  // const handleChange = (event) => {
  //   setSelectedHospitalId(event.target.value);
  // };
  const handleSpecialitiesChange = (event) => {
    setSpecId(event.target.value);
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const getDoctorsList = async () => {
    setLoading(true);
    const params = {
      per_page: 10,
      page: currentPage,
      searchTerm: debouncedSearchTerm,
      // hospitals_id: selectedHospitalId !== "all" ? selectedHospitalId : "",
      specialties_id: specId !== "all" ? specId : "",
    };
    try {
      const response = await getDoctorList(params);
      if (response.data.status === true) {
        setDoctorsList(response.data.responseBody.data);
        setTotalPages(response.data.responseBody.last_page);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const getHospitals = async () => {
  //   try {
  //     const response = await getHospitalsList();
  //     if (response.data.status === true) {
  //       setHospitalsList(response.data.responseBody);
  //     }
  //   } catch (error) {
  //     console.log("error retrieving hospital list", error);
  //   }
  // };

  const getSpecialists = async () => {
    try {
      const response = await getSpecialistList();
      if (response.data.status === true) {
        setSpecialtiesList(response.data.responseBody);
      }
    } catch (error) {
      console.log("error retrieving hospital list", error);
    }
  };

  useEffect(() => {
    // getHospitals();
    getSpecialists();
  }, []);


  useEffect(() => {
    getDoctorsList();
  }, [currentPage, specId, debouncedSearchTerm]);

  useEffect(() => {
    const topElement = topRef.current;

    if (topElement) {
      const navbarHeight = 800; // Replace with your navbar's height in pixels
      const topPosition = topElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <>
      <div>
        {/* <div className="header-image" ref={topRef}>
          <div className="absolute-image-container">
            <img
              src={banner.doctorListBanner} // Replace with your image source
              alt="Doctors"
              className="absolute-image"
            />
            <div className="image-text-about">
              <h1 className="text-about">Doctor List</h1>
              <p className="text-health">
                Our Team of Specialists <br />
                Delivering Excellence in Every Field
              </p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div> */}
        <div className="content-image" ref={topRef}>
          <div className="image-container">
            <img
              className="background-image"
              src={banner.doctorDetailsBanner}
              alt="Banner"
            />
            <div className="text-container">
              <h1 className="text-title">Doctor List</h1>
              <p className="text-paragraph">
                Our Team of Specialists <br />
                Delivering Excellence in Every Field
              </p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <HomeBooking noMargin footerData={footerData} />
        </div>
        <div className="commit-sec">
          <div className="doctor-title mt-3">Doctor List</div>
          <Grid container spacing={2} className="mt-4">
            <Grid item xs={12} md={6}>
              {" "}
              <SearchBar
                value={searchTerm}
                onChange={(newValue) => setSearchTerm(newValue)} // Handle user typing
                onRequestSearch={() => handleSearchChange()}
                onCancelSearch={() => setSearchTerm("")}
                placeholder="Search for Doctors"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ textAlign: "end" }}
              className="filter-drop"
            >
              {/* <FormControl
                style={{ width: "200px", marginRight: "20px" }}
                className="filterSpecialist"
              >
                <InputLabel id="demo-simple-select-label filter-drop-data">
                  Filer By Hospital
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="filter-drop-data-1"
                  label="Filer By Hospital"
                  value={selectedHospitalId}
                  onChange={handleChange}
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {hospitalsList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl> */}
              <FormControl
                style={{ width: "200px" }}
                className="filterSpecialist"
              >
                <InputLabel id="demo-simple-select-label filter-drop-data">
                  Filter By Specialist
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="filter-drop-data-1"
                  label="Filter By Specialist"
                  value={specId}
                  onChange={handleSpecialitiesChange}
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {specialties?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className="pt-5">
            <Grid container spacing={3}>
              {loading ? (
                // Shimmer effect while loading
                Array.from(new Array(6)).map((_, index) => (
                  <Grid item xs={12} sm={6} md={6} key={index} className="pt-5">
                    <div
                      className="d-flex gap-3 doct-details"
                      style={{ flexWrap: "wrap" }}
                    >
                      <div>
                        <Skeleton
                          variant="circular"
                          width={210}
                          height={210}
                        />
                      </div>
                      <div className="doctor-list-sec-data">
                        <Skeleton variant="text" width={180} height={30} />
                        <Skeleton variant="text" width={150} height={20} />
                        <Skeleton variant="text" width={200} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                        <div
                          className="mt-4 d-flex gap-2"
                          style={{ flexWrap: "wrap" }}
                        >
                          <Skeleton variant="rectangular" width={120} height={40} style={{ borderRadius: "8px" }} />
                          <Skeleton variant="rectangular" width={180} height={40} style={{ borderRadius: "8px" }} />
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))
              ) : doctorsList.length > 0 ? (
                doctorsList.map((doctor, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={6} key={index} className="pt-5">
                      <div
                        className="d-flex gap-3 doct-details"
                        style={{ flexWrap: "wrap" }}
                      >
                        <div>
                          <img
                            src={doctor.image}
                            alt={doctor.name}
                            style={{
                              width: "210px",
                              height: "210px",
                              borderRadius: "50%",
                            }}
                            className="doct-img"
                          />
                        </div>
                        <div className="doctor-list-sec-data">
                          <h6 style={{ marginBottom: "0px" }}>{doctor.name}</h6>
                          <span>{doctor.type}</span>
                          <p className="mt-1">{doctor.title}</p>
                          <p>{doctor.specialties}</p>
                          <p>
                            Experience: <b>{doctor.experience}</b>
                          </p>
                          {/* <p>
                          <img src={images.userLocation} alt="" />{" "}
                          {doctor.hospitals}
                        </p> */}
                          <div
                            className="mt-3 d-flex gap-2"
                            style={{ flexWrap: "wrap" }}
                          >
                            <button
                              variant="outlined"
                              size="small"
                              className="view-profile-btn"
                              onClick={() =>
                                navigate(`/view-profile/${doctor?.id}`)
                              }
                            >
                              View Profile
                            </button>
                            <button
                              variant="contained"
                              size="small"
                              className="book-appoinment-btn"
                              onClick={() =>
                                navigate(`/view-profile/${doctor?.id}`, {
                                  state: { section: "book-appointment" },
                                })
                              }
                            >
                              Book An Appointment
                            </button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })) : (
                <h2 className="pt-4" style={{ marginLeft: "auto", marginRight: "auto" }}>
                  No Data Found
                </h2>
              )}
            </Grid>
          </div>

          <br />
          {!loading && doctorsList.length > 0 && (
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center" // Centers the content horizontally
              className="mt-5 mb-5 d-flex pagination-stack"
              sx={{
                padding: "16px", // Optional padding for spacing
                borderRadius: "8px", // Optional rounded corners
              }}
            >
              <CustomPagination
                count={totalPages} // Total number of pages
                page={currentPage} // Current page
                onChange={handlePageChange} // Page change handler
                sx={{
                  "& .MuiPaginationItem-ellipsis": {
                    color: "#01ADEC", // Apply color to the ellipsis (if any)
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#01ADEC", // Background color when a page is selected
                    color: "#fff", // White text when selected
                  },
                }}
              />
            </Stack>
          )}


        </div>
        <Booking headerData={headerData} footerData={footerData} />
      </div>
    </>
  );
};

export default DoctorList;

import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Link } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "../Footer/Footer.css"; // Ensure this file contains the required styles
import { images } from "../../Config/images";
import { getCustomLink } from "../../Services/services";
import { Link as RouterLink } from "react-router-dom";

const Footer = ({ headerData, footerData }) => {
  const currentYear = new Date().getFullYear();

  const [customApiData, setCustomApiData] = useState([]);
  const data = [
    {
      "id": 3,
      "user_id": 1,
      "title": "Privacy Policy",
      "side": "1",
      "link": null,
      "slug": "privacy_policy",
      "content": "<p><strong>What is Lorem Ipsum?</strong><br />\r\n<br />\r\n<strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>\r\n\r\n<p><strong>Why do we use it?</strong></p>\r\n\r\n<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using &#39;Content here, content here&#39;, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for &#39;lorem ipsum&#39; will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>",
      "is_header": "1",
      "is_footer": "1",
      "is_active": "1",
      "created_at": "2024-10-11T05:17:11.000000Z",
      "updated_at": "2024-12-13T13:46:18.000000Z"
    },
    {
      "id": 4,
      "user_id": 1,
      "title": "Web Link TEST",
      "side": "2",
      "link": "https://anaxlifescience.com/privacy_policy.php",
      "slug": "web_link_test",
      "content": null,
      "is_header": "1",
      "is_footer": "1",
      "is_active": "1",
      "created_at": "2024-10-15T02:35:42.000000Z",
      "updated_at": "2025-01-22T06:34:32.000000Z"
    }
  ];

  useEffect(() => {
    fetchCustomApi();

  }, []);

  const fetchCustomApi = async () => {
    const params = {
      type: "footer",
    };
    try {
      const resp = await getCustomLink({ params });

      if (resp.data.code === 200) {
        setCustomApiData(resp.data.responseBody);
        localStorage.setItem('customApiData', JSON.stringify(resp.data.responseBody));
      }
    } catch (error) {
      console.log("error getting custom api", error)
    }
  }

  useEffect(() => {
    const storedData = localStorage.getItem('customApiData');
    if (storedData) {
      setCustomApiData(JSON.parse(storedData));
    } else {
      fetchCustomApi();
    }
  }, []);

  return (
    <>
      <div className="footer-wrapper">
        <footer className="footer">
          {/* Main Sections */}
          <Grid container spacing={4}>
            {/* Section 1: About */}
            <Grid item xs={12} sm={6} md={4} className="footer-section">
              <img
                src={footerData?.logo}
                alt="Global Hospital Logo"
                className="footer-logo mt-4"
              />
              <Typography className="footer-description mt-4">
                {footerData?.description}
              </Typography>
              <div className="social-icons mt-5">
                <Link
                  href={headerData?.facebook_link}
                  aria-label="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
                <Link
                  href={headerData?.youtube_link}
                  aria-label="YouTube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-youtube"></i>
                </Link>
                <Link
                  href={headerData?.instagram_link}
                  aria-label="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-instagram"></i>
                </Link>
                <Link
                  href={headerData?.linkedin_link}
                  aria-label="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-linkedin-in"></i>
                </Link>
              </div>
            </Grid>

            {/* Section 2: Quick Links */}
            <Grid item xs={12} sm={6} md={3} className="footer-section">
              <Typography variant="h6" className="footer-title mt-4 mx-4">
                QUICK LINK
              </Typography>
              <ul className="footer-links mx-4">
                <li class="text-uppercase">
                  <Link href="/aboutus">ABOUT</Link>
                </li>
                <li class="text-uppercase">
                  <Link href="/specialties">SPECIALTIES</Link>
                </li>
                <li class="text-uppercase">
                  <Link href="#">SERVICES</Link>
                </li>
                {/* <li>
                  <Link href="#">DEPARTMENT</Link>
                </li> */}
                <li class="text-uppercase">
                  <Link href="/health-screening">HEALTH SCREENING & PACKAGES</Link>
                </li>
                <li class="text-uppercase">
                  <Link href="/contact">CONTACT</Link>
                </li>
                {customApiData && customApiData.map((page, index) => (
                  <li key={index} class="text-uppercase">
                    <Link
                      href={`/${page.slug}`}
                    >
                      {page.title}
                    </Link>

                  </li>
                ))}
                {/* <li>
                  <Link href="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link href="/privacy-policy">Privacy Policy</Link>
                </li> */}
              </ul>
            </Grid>

            {/* Section 3: Contact Us */}
            <Grid item xs={12} sm={12} md={5} className="footer-section">
              <Typography variant="h6" className="footer-title mt-4 mx-4" style={{ paddingLeft: "5px" }}>
                CONTACT US
              </Typography>
              <ul className="footer-contact-list">
                <li className="footer-contact">
                  <Grid container>
                    <Grid item xs={12} md={4} lg={5} sm={5}>
                      {" "}
                      <Typography variant="subtitle1">LOCATION :</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={7} sm={7}>
                      <Typography>{footerData?.address}</Typography>
                    </Grid>
                  </Grid>
                </li>
                <li className="footer-contact">
                  <Grid container>
                    <Grid item xs={12} md={4} lg={5} sm={5}>
                      {" "}
                      <Typography variant="subtitle1">RECEPTION :</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={7} sm={7}>
                      <Typography>
                        {footerData?.reception_number && (
                          <>
                            <a href={`tel:${footerData.reception_number.split("/")[0].trim()}`} className="footer-link anchor">
                              {footerData.reception_number.split("/")[0].trim()} /{" "}
                            </a>
                            <a
                              href={`tel:${footerData.reception_number.split("/")[0].trim().slice(0, -2)}${footerData.reception_number.split("/")[1].trim()}`}
                              className="footer-link anchor"
                            >
                              {footerData.reception_number.split("/")[1].trim()}
                            </a>
                          </>
                        )}
                      </Typography>

                    </Grid>
                  </Grid>
                </li>
                <li className="footer-contact">
                  <Grid container>
                    <Grid item xs={12} md={4} lg={5} sm={5}>
                      {" "}
                      <Typography variant="subtitle1">APPOINTMENT :</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={7} sm={7}>
                      <Typography>
                        <a
                          href={`tel:${footerData?.appointment_number}`}
                          className="footer-link anchor"
                        >
                          {footerData?.appointment_number}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
                <li className="footer-contact">
                  <Grid container>
                    <Grid item xs={12} md={4} lg={5} sm={5}>
                      {" "}
                      <Typography variant="subtitle1">EMERGENCY :</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={7} sm={7}>
                      <Typography>
                        <a
                          href={`tel:${footerData?.emergency_number}`}
                          className="footer-link anchor"
                        >
                          {footerData?.emergency_number}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
                <li className="footer-contact">
                  <Grid container>
                    <Grid item xs={12} md={4} lg={5} sm={5}>
                      {" "}
                      <Typography variant="subtitle1">EMAIL :</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={7} sm={7}>
                      <Typography>
                        <a
                          href={`mailto:${footerData?.email}`}
                          className="footer-link anchor"
                        >
                          {footerData?.email}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              </ul>
            </Grid>
          </Grid>

          {/* Footer Bottom */}
          <div className="footer-bottom">
            <Typography>© Copyright {currentYear}. All Rights Reserved.</Typography>
            <Typography className="footer-developer">
              Design And Developed by{" "}
              <Link
                href="https://srashtasoft.com/"
                target="_blank"
                className="developer-link"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "600",
                }}
              >
                Srashtasoft
              </Link>
              <Link
                href="#top"
                className="back-to-top mx-2"
                aria-label="Back to top"
              >
                <ArrowUpwardIcon className="arrow-icon" />
              </Link>
            </Typography>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;

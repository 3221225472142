import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../componant/Header/Header";
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AbouUs/AboutUs";
import Specialties from "../pages/Specialties/Specialties";
import Contact from "../pages/Contact/Contact";
import Navbar from "../componant/Navbar/Navbar";
import Footer from "../componant/Footer/Footer";
import DoctorList from "../pages/DoctorList/DoctorList";
import ViewProfile from "../pages/ViewProfile/ViewProfile";
import { doctorImages } from "../Config/images";
import HealthScreen from "../pages/HealthScreen/HealthScreen";
import HealthCheckDetails from "../pages/HealthScreen/HealthCheckDetails";
import SpecialistHistory from "../pages/Specialties/SpecialitiesHistory";
import { getHeaderFooter, getSpecialties } from "../Services/services";
import NotFound from "../pages/404NotFound/NotFound";
import TermsAndCondition from "../componant/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "../componant/PrivacyPolicy/PrivacyPolicy";
import CustomPage from "../componant/CustomPage/CustomPage";


const HEADER_STORAGE_KEY = "headerData"; // Key for local storage
const FOOTER_STORAGE_KEY = "footerData"; // Key for local storage
const Routers = () => {

  const [headerData, setHeaderData] = React.useState();
  const [footerData, setFooterData] = React.useState();
  const [specialties, setSpecialties] = React.useState([]);

  const getData = async () => {
    try {
      const response = await getHeaderFooter();
      if (response.status == 200) {

        const header = response.data.responseBody.header;
        const footer = response.data.responseBody.footer;

          // Save to state
        setHeaderData(header);
        setFooterData(footer);

        localStorage.setItem(HEADER_STORAGE_KEY, JSON.stringify(header));
        localStorage.setItem(FOOTER_STORAGE_KEY, JSON.stringify(footer));

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    const storedHeaderData = localStorage.getItem(HEADER_STORAGE_KEY);
    const storedFooterData = localStorage.getItem(FOOTER_STORAGE_KEY);
    if (storedHeaderData && storedFooterData) {
      // Use cached data
      setHeaderData(JSON.parse(storedHeaderData));
      setFooterData(JSON.parse(storedFooterData));
    } else {
      // Fetch from API if no cached data
      getData();
    }
  }, []);

  useEffect(() => {
    getSpecialtiesDetails()
  }, [])

  const getSpecialtiesDetails = async () => {
    try {
      const resp = await getSpecialties();
      if (resp.status === 200) {
        setSpecialties(resp.data.responseBody);
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    const removeHashFromUrl = () => {
      if (window.location.hash === "#top") {
        window.history.replaceState(null, "", window.location.pathname);
      }
    };

    // Add a hashchange event listener
    window.addEventListener("hashchange", removeHashFromUrl);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("hashchange", removeHashFromUrl);
    };
  }, []);

  return (
    <Router>
      <Header
        headerData={headerData}
        footerData={footerData}
      />
      <Navbar headerData={headerData} />
      <Routes>
        <Route path="/"
          element={
            <Home
              headerData={headerData}
              footerData={footerData}
              specialties={specialties}
            />}
        />
        <Route path="/aboutus"
          element={
            <AboutUs
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/specialties"
          element={
            <Specialties
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/contact"
          element={
            <Contact
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/doctor-list"
          element={
            <DoctorList
              headerData={headerData}
              footerData={footerData}
              specialties={specialties}
            />}
        />
        <Route
          path="/view-profile/:id"
          element={
            <ViewProfile
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/health-screening"
          element={<HealthScreen headerData={headerData} footerData={footerData} specialties={specialties} />}
        />
        <Route path="/health-checkup/:id"
          element={
            <HealthCheckDetails
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/health-specialist/:id"
          element={
            <SpecialistHistory
              headerData={headerData}
              footerData={footerData}
            />}
        />
    
        {/* <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/privacy-policy-default" element={<PrivacyPolicy />} /> */}
        <Route path="/:page" element={<CustomPage  footerData={footerData}/>}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer
        headerData={headerData}
        footerData={footerData}
      />
    </Router>
  );
};

export default Routers;

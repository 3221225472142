import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid2,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
} from "@mui/material";
import "./Contact.css";
import { images } from "../../Config/images";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import Booking from "../../componant/Booking/Booking";
import { getContactInformation, contactEmergency } from "../../Services/services";
import { toast } from "react-toastify"; // Import the toast library
import "react-toastify/dist/ReactToastify.css";

const Contact = ({
  headerData,
  footerData,
}) => {

  const [contactInformation, setContactInformation] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const topRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mapSrc, setMapSrc] = useState("");
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Contact us",
      url: "/contact",
    },
  ];

  const fetchContactInfo = async () => {
    try {
      const res = await getContactInformation();
      console.log("Contact Information: ", res);
      if (res.data.status === true) {
        setContactInformation(res.data.responseBody)

        // Extract src from iframe
        const iframe = res.data.responseBody.google_map_iframe;
        const srcMatch = iframe.match(/src="([^"]+)"/);
        if (srcMatch) {
          setMapSrc(srcMatch[1]); // Extracted src value
        }
      }
    } catch (error) {
      console.error("Error fetching contact information: ", error);
    }
  };
  // Call getContactInformation when the component is mounted
  useEffect(() => {
    fetchContactInfo();
  }, []); // Empty dependency array ensures this runs only once


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const nameRegex = /^[a-zA-Z\s]{3,}$/;
    const mobileRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name.trim()) {
      toast.error("Enter your name.");
      return false;
    }
    if (!nameRegex.test(formData.name)) {
      toast.error(
        "Name must be at least 3 characters and only contain letters."
      );
      return false;
    }

    if (!formData.phone) {
      toast.error("Enter your mobile number.");
      return false;
    }
    if (!mobileRegex.test(formData.phone)) {
      toast.error("Mobile number must be exactly 10 digits.");
      return false;
    }

    if (!formData.email.trim()) {
      toast.error("Enter your email.");
      return false;
    }
    if (!emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (!formData.message.trim()) {
      toast.error("Enter your message.");
      return false;
    }
    if (formData.message.trim().length < 10) {
      toast.error("Message must be at least 10 characters long.");
      return false;
    }

    return true; // If all validations pass, return true
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }


    try {
      const response = await contactEmergency(formData);
      toast.success(response.data.message);
      // Reset the form
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: ""
      });
      setIsSubmitting(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "An unexpected error occurred.";
      toast.error(errorMessage);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const topElement = topRef.current;

    if (topElement) {
      const navbarHeight = 800; // Replace with your navbar's height in pixels
      const topPosition = topElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <>
      <div>
        {/* <div className="header-image" ref={topRef}>
          <div className="absolute-image-container">
            <img
              src={images.contactslider} // Replace with your image source
              alt="Doctors"
              className="absolute-image"
            />
            <div className="image-text-about">
              <h1 className="text-about">Contact Us</h1>
              <p className="text-health">
                Let’s Stay Connected <br />
                Reach Out to Global Hospital Anytime.
              </p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div> */}

        <div className="content-image" ref={topRef}>
          <div className="image-container">
            <img
              className="background-image"
              src={images.contactslider}
              alt="Banner"
            />
            <div className="text-container">
              <h1 className="text-title">Contact us</h1>
              <p className="text-paragraph">
              Let’s Stay Connected <br />
              Reach Out to Global Hospital Anytime.
              </p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <HomeBooking noMargin footerData={footerData} />
      </div>
      <div className="contact-div">
        <Grid container spacing={4}>
          {/* Contact Information Section */}
          <Grid item xs={12} md={7} sm={12}>
            <Box className="ContactDetail-box">
              <Box className=" d-flex justify-content-between contact-page">
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className="mb-3">
                      <img
                        src={images?.locationInfo}
                        alt="open-hours"
                        className="mb-1"
                      />
                    </div>
                    <h6 className="contact-title">Address</h6>
                    <p className="contact-add">
                      {contactInformation?.address}
                    </p>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className="mb-3">
                      <img
                        src={images?.customer}
                        alt="open-hours"
                        className="mb-1"
                      />
                    </div>
                    <h6 className="contact-title">Contact No</h6>
                    <div>
                      <div className="d-flex gap-4">
                        <p className="contact-add margin-bottom-5px">
                          Reception:
                        </p>
                        <p className="contact-add margin-bottom-5px">
                          {contactInformation?.reception_number && (
                            <>
                              <a href={`tel:${contactInformation?.reception_number.split("/")[0].trim()}`} className="anchorBlack contact-numbers">
                                {contactInformation?.reception_number.split("/")[0].trim()}/
                              </a>
                              <a
                                href={`tel:${contactInformation?.reception_number.split("/")[0].trim().slice(0, -2)}56`}
                                className="anchorBlack contact-numbers"
                              >
                                {contactInformation?.reception_number.split("/")[1].trim()}
                              </a>
                            </>
                          )}
                        </p>
                      </div>
                      <div className="d-flex gap-3">
                        <p className="contact-add margin-bottom-5px">
                          Emergency:
                        </p>
                        <p className="contact-add margin-bottom-5px">
                          <a href={`tel:${contactInformation?.emergency_number}`}
                            className="anchorBlack contact-numbers">
                            {contactInformation?.emergency_number}
                          </a>
                        </p>
                      </div>
                      <div className="d-flex gap-2">
                        <p className="contact-addmargin-bottom-5px">
                          Appointment:
                        </p>
                        <p className="contact-add margin-bottom-5px">
                          <a href={`tel:${contactInformation?.appointment_number}`}
                            className="anchorBlack contact-numbers">
                            {contactInformation?.appointment_number}
                          </a>
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box className="mt-5 d-flex justify-content-between">
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <img
                        src={images?.openHours}
                        alt="open-hours"
                        className="mb-1"
                      />
                    </div>
                    <h6 className="contact-title">Opening Hours</h6>
                    <div className="mt-3">
                      <div className="d-flex gap-2">
                        <p
                          className="contact-add"
                          style={{ marginBottom: "5px" }}
                        >
                          OPD Timings:
                        </p>
                        <p className="contact-add margin-bottom-5px">
                          {contactInformation?.opd_time}
                        </p>
                      </div>
                      <div className="d-flex gap-2">
                        <p
                          className="contact-add"
                          style={{ marginBottom: "5px" }}
                        >
                          Emergency:
                        </p>
                        <p className="contact-add margin-bottom-5px">
                          {contactInformation?.emergency_time}
                        </p>
                      </div>

                    </div>
                  </Grid>
                  <Grid item md={6} sm={6}>
                    <div className="mb-3">
                      <img
                        src={images?.emailData}
                        alt="mail-info"
                        className="mb-1"
                      />
                    </div>
                    <h6 className="contact-title">Mail</h6>
                    <p className="contact-add" style={{ marginBottom: "5px" }}>
                      <p style={{ marginBottom: "5px" }}>
                        <a href={`mailto:${contactInformation?.email}`}
                          className="anchorBlack">
                          {contactInformation?.email}
                        </a>
                      </p>
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {/* Emergency Form Section */}
          <Grid item xs={12} md={5} sm={12}>
            <Box
              className="shadow rounded-4 border border-primary-subtle"
              sx={{ height: "550px" }}
            >
              <Typography
                variant="h4"
                sx={{
                  height: "80px",
                  backgroundColor: "#354895",
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "36px",
                  lineHeight: "50.76px",
                  letterSpacing: "2%",
                  padding: "1rem 0 0 1.5rem ",
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                  marginBottom: "1rem",
                }}
                className="contact-form-title"
              >
                Need emergency?
              </Typography>
              <form onSubmit={handleSubmit}>
                <div className="contact-form-div">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    className="contact-input"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="contact-form-div">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email id"
                    className="contact-input"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="contact-form-div">
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Enter mobile number"
                    className="contact-input"
                    value={formData.phone}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) { // Allows only numeric values
                        handleChange(e);
                      }
                    }}
                    maxLength="10" // Restricts to 10 digits
                    required
                    pattern="[0-9]{10}"
                    title="Please enter a valid 10-digit mobile number."
                  />
                </div>
                <div className="contact-form-div">
                  <textarea
                    name="message"
                    rows={4}
                    placeholder="Write your message"
                    className="contact-input-textarea"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="contact-form-div">
                  <button className="contact-submit-btn" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit Now"}
                  </button>
                </div>
              </form>

            </Box>
          </Grid>
        </Grid>
        <div className="mt-5 contactDatamap" style={{ width: "100%" }}>
          <iframe
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.524960554315!2d72.50872609999999!3d23.041207999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b4f80a8d1bf%3A0x921f95d936981600!2sGlobal%20Hospital!5e0!3m2!1sen!2sin!4v1733205175769!5m2!1sen!2sin"
            src={mapSrc}
            height="645"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>

        </div>

      </div>
      <div>
        <Booking
          headerData={headerData}
          footerData={footerData}
        />
      </div>
    </>
  );
};

export default Contact;

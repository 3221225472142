import React from "react";
import { Grid, Box, Typography, Button, Paper } from "@mui/material";
import { images } from "../../Config/images";
import "./Booking.css"; // Make sure to import your CSS file
import { useNavigate } from "react-router-dom";

const Booking = ({
  headerData, 
  footerData,
}) => {
  const navigate = useNavigate();

  return (
    // <Box className="help-section" sx={{ flexGrow: 1 }}>
    //   <Grid container spacing={0}>
    //     {/* Help Box */}
    //     <Grid
    //       item
    //       xs={12} // Full width on small screens
    //       md={7} // Takes 7 parts on medium and up screens
    //       sx={{ display: "flex", flexDirection: "column" }}
    //     >
    //       <Paper
    //         className="help-box"
    //         sx={{
    //           padding: 2,
    //           backgroundColor: "#354895",
    //           borderRadius: 0,
    //           color: "white",
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "space-between",
    //           height: "100%", // Equal height for both boxes
    //         }}
    //       >
    //         <Grid container spacing={2} alignItems="center">
    //           <Grid item>
    //             <img
    //               src={images.emargancy}
    //               alt="Help Icon"
    //               className="help-icon"
    //             />
    //           </Grid>
    //           <Grid item>
    //             <Typography variant="h5" className="need-help mt-3">
    //               Need Help
    //             </Typography>
    //             <Typography className="enquiries mt-4">
    //               For enquiries please call
    //             </Typography>
    //             <div className="contact-info mx-5">
    //               <Button
    //                 className="emargancy"
    //                 variant="contained"
    //                 startIcon={
    //                   <img
    //                     src={images.groupcall}
    //                     alt="Phone"
    //                     className="phone-icon"
    //                   />
    //                 }
    //                 component="a"
    //                 href="tel:+917576001766"
    //                 target="_blank"
    //               >
    //                 +91 75760 01766
    //               </Button>

    //               <Button
    //                 className="emargancy"
    //                 variant="contained"
    //                 startIcon={
    //                   <img
    //                     src={images.whatshap}
    //                     alt="Phone"
    //                     className="phone-icon"
    //                   />
    //                 }
    //                 component="a"
    //                 href="https://wa.me/919898598985?text=Welcome%20to%20Global%20Hospital"
    //                 target="_blank"
    //               >
    //                 +91 98985 98985
    //               </Button>
    //             </div>
    //           </Grid>
    //         </Grid>
    //       </Paper>
    //     </Grid>

    //     {/* Appointment Box */}
    //     <Grid
    //       item
    //       xs={12} // Full width on small screens
    //       md={5} // Takes 5 parts on medium and up screens
    //       sx={{ display: "flex", flexDirection: "column" }}
    //     >
    //       <Paper
    //         className="appointment-box"
    //         elevation={3}
    //         sx={{
    //           padding: 2,
    //           backgroundColor: "#01ABEB",
    //           borderRadius: 0,
    //           color: "white",
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "space-between",
    //           height: "100%", // Equal height for both boxes
    //         }}
    //       >
    //         <Typography variant="h5" className="Appointment mt-3">
    //           Book An Appointment
    //         </Typography>
    //         <Typography variant="body2" className="mt-1">
    //           Dummy text of the printing and typesetting industry.
    //         </Typography>
    //         <Button variant="contained" className="printing mt-4">
    //           Book An Appointment
    //         </Button>
    //       </Paper>
    //     </Grid>
    //   </Grid>
    // </Box>
    <>
      <div>
        <Grid container>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <div className="need-help-sec">
              <div>
                <img
                  src={images.emargancy}
                  alt="Help Icon"
                  className="help-icon"
                />
              </div>
              <div className="need-help-sec-data">
                <Typography variant="h5" className="need-help ">
                  Need Help
                </Typography>
                <Typography className="enquiries mt-4">
                  For enquiries please call
                </Typography>
                <div className="contact-info">
                  <Button
                    className="emargancy"
                    variant="contained"
                    startIcon={
                      <img
                        src={images.phoneCall}
                        alt="Phone"
                        className="phone-icon"
                      />
                    }
                    component="a"
                    href={`tel:${headerData?.help_phone}`}
                    target="_blank"
                  >
                    {headerData?.help_phone}
                  </Button>

                  <Button
                    className="emargancy"
                    variant="contained"
                    startIcon={
                      <img
                        src={images.whatshap}
                        alt="Phone"
                        className="phone-icon"
                      />
                    }
                    component="a"
                    href={`https://wa.me/${headerData?.help_whatsapp.replace(/\+|\s/g, "")}?text=Welcome%20to%20Global%20Hospital`}
                    // href='https://wa.me/919227711852?text=Welcome%20to%20Global%20Hospital'
                    target="_blank"
                  >
                     {headerData?.help_whatsapp}
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <div className="book-appoiment">
            <Typography variant="h5" className="Appointment">
               Book An Appointment
             </Typography>
            <Typography variant="body2" className="mt-1" style={{color:"white"}}>
              Expert care, just a click away! Schedule your appointment with us today!
             </Typography>
            <button variant="contained" className="printing mt-4" onClick={() => navigate("/doctor-list")}>
               Book An Appointment
             </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Booking;

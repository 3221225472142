import axios from "axios";
import {urls} from "../Config/urls";

export const request = axios.create({
  baseURL: urls.apiBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

async function getToken() {
    try {
        const response = await axios.post('https://global.srashtasoft.in/admin/api/token', {    
        });
        const token = response.data.token;  
        localStorage.setItem('user', JSON.stringify({ token }));
        return token;


    } catch (error) {
        console.error("Error fetching token:", error);
        throw error;
    }
}
request.interceptors.request.use(
 async function (config) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user?.token) {
        console.log("No token found, fetching new token");
        const token = await getToken(); 
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
    },
  function (error) {
    console.log("Error in request interceptor:", error);
    return Promise.reject(error);
  }
);

// Interceptor for axiosInstance
request.interceptors.response.use(
  (response) => {
    // Return the resolved response as a promise
    return Promise.resolve(response);
  },
  async (error) => {
    console.error("Response error:", error);
    // Handle 401 Unauthorized errors
    if (error.response && error.response.status === 401) {
      // Clear local storage and redirect to the home page
      console.warn("Unauthorized, token might be expired. Redirecting to login.");
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export const multipartRequest = axios.create({
  baseURL: urls.apiBaseUrl + "/api/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

multipartRequest.interceptors.request.use(
    async function (config) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (!user?.token) {
        console.log("No token found, fetching new token");
        const token = await getToken(); // Fetch a new token
        user = { token }; // Update the user object
        localStorage.setItem('user', JSON.stringify(user)); // Store the new token in localStorage
      }
  
      if (user.token) {
        config.headers.Authorization = `Bearer ${user.token}`;
        console.log("Token added to multipart request headers:", config.headers.Authorization);
      }
  
      return config;
    },
    function (error) {
      console.error("Error in multipart request interceptor:", error);
      return Promise.reject(error);
    }
  );
  
  multipartRequest.interceptors.response.use(
    (response) => {
      // Return the resolved response as a promise
      console.log("Multipart response received:", response);
      return Promise.resolve(response);
    },
    async (error) => {
      console.error("Multipart response error:", error);
      // Handle 401 Unauthorized errors
      if (error.response && error.response.status === 401) {
        console.warn("Unauthorized, token might be expired. Redirecting to login.");
        localStorage.clear();
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
  );
  
import React from 'react'
import Routers from './Routers/Routers'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div>
      <ToastContainer/>
      <Routers/>
    </div>
  )
}

export default App
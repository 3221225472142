import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { banner, images } from "../../Config/images"; // Make sure your image paths are correct
import "./AboutUs.css"; // Import your CSS file
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import Booking from "../../componant/Booking/Booking";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import Shimmer from "../../componant/Shimmer/Shimmer";
import {
  getAboutSection,
  getHealthAspirations,
  getVisionMission,
  getCoreValue,
  getOurCommitment,
  getQualityStandards,
} from "../../Services/services"


const AboutUs = ({
  headerData,
  footerData,
}) => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "About Us",
      url: "/aboutus",
    },
  ];
  const topRef = useRef(null);

  const [aboutData, setAboutData] = useState(null);
  const [aboutLoading, setAboutLoading] = useState(false);

  const [healthAspirationsData, setHealthAspirationsData] = useState(null);
  const [healthLoading, setHealthLoading] = useState(false);

  const [visionMissionData, setVisionMissionData] = useState(null);
  const [visionLoading, setVisionLoading] = useState(false);

  const [coreValueData, setCoreValueData] = useState(null);
  const [coreLoading, setCoreLoading] = useState(false);

  const [ourCommitmentData, setOurCommitmentData] = useState(null);
  const [commitmentLoading, setCommitmentLoading] = useState(false);

  const [qualityStandardsData, setQualityStandardsData] = useState(null);
  const [qualityLoading, setQualityLoading] = useState(false);

  // Intersection Observers for Lazy Loading
  const [aboutRef, aboutInView] = useInView({ triggerOnce: true });
  const [healthRef, healthInView] = useInView({ triggerOnce: true });
  const [visionRef, visionInView] = useInView({ triggerOnce: true });
  const [coreRef, coreInView] = useInView({ triggerOnce: true });
  const [commitmentRef, commitmentInView] = useInView({ triggerOnce: true });
  const [qualityRef, qualityInView] = useInView({ triggerOnce: true });

  // Fetch Data Functions
  const fetchAboutSection = async () => {
    setAboutLoading(true);
    const response = await getAboutSection();
    if (response.data.status === true) {
      setAboutData(response.data.responseBody);
      setAboutLoading(false);
    }
  };

  const fetchHealthAspirations = async () => {
    setHealthLoading(true);
    const response = await getHealthAspirations();
    if (response.data.status === true) {
      setHealthAspirationsData(response.data.responseBody);
      setHealthLoading(false);
    }
  };

  const fetchVisionMission = async () => {
    setVisionLoading(true);
    const response = await getVisionMission();
    if (response.data.status === true) {
      setVisionMissionData(response.data.responseBody);
      setVisionLoading(false);
    }
  };

  const fetchCoreValue = async () => {
    setCoreLoading(true);
    const response = await getCoreValue();
    if (response.data.status === true) {
      setCoreValueData(response.data.responseBody);
      setCoreLoading(false);
    }
  };

  const fetchOurCommitment = async () => {
    setCommitmentLoading(true);
    const response = await getOurCommitment();
    if (response.data.status === true) {
      setOurCommitmentData(response.data.responseBody);
      setCommitmentLoading(false);
    }
  };

  const fetchQualityStandards = async () => {
    setQualityLoading(true);
    const response = await getQualityStandards();
    if (response.data.status === true) {
      setQualityStandardsData(response.data.responseBody);
      setQualityLoading(false);
    }
  };

  // Trigger API Calls on Section Visibility
  useEffect(() => {
    if (aboutInView && !aboutData) fetchAboutSection();
    if (healthInView && !healthAspirationsData) fetchHealthAspirations();
    if (visionInView && !visionMissionData) fetchVisionMission();
    if (coreInView && !coreValueData) fetchCoreValue();
    if (commitmentInView && !ourCommitmentData) fetchOurCommitment();
    if (qualityInView && !qualityStandardsData) fetchQualityStandards();
  }, [
    aboutInView,
    healthInView,
    visionInView,
    coreInView,
    commitmentInView,
    qualityInView,
  ]);

  useEffect(() => {
    const topElement = topRef.current;

    if (topElement) {
      const navbarHeight = 800; // Replace with your navbar's height in pixels
      const topPosition = topElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <>
      {/* <div className="header-image" ref={topRef}>
        <div className="absolute-image-container">
          <img
            src={banner.aboutUsBanner} // Replace with your image source
            alt="Doctors"
            className="absolute-image"
          />
          <div className="image-text-about">
            <h1 className="text-about">About us</h1>
            <p className="text-health">
              Global Hospital <br />
              Trusted Care, Trusted Team
            </p>
            <div className="breadcrumb">
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div> */}

      <div className="content-image" ref={topRef}>
        <div className="image-container">
          <img
            className="background-image"
            src={banner.aboutUsBanner}
            // src={banner.aboutUSNewBanner}
            alt="Banner"
          />
          <div className="text-container">
            <h1 className="text-title">About us</h1>
            <p className="text-paragraph">
              Global Hospital <br />
              Trusted Care, Trusted Team
            </p>
            <div className="breadcrumb">
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div>

      <HomeBooking noMargin footerData={footerData} />

      <div ref={aboutRef} className="about-sec" >
        {aboutLoading ? (
          <Shimmer style={{ height: "150px", marginBottom: "20px" }} />
        ) : (
          aboutData && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={7} lg={7} sm={12}>
                <div>
                  {/* Title */}
                  <h2
                    className="Experience-about-1"
                    dangerouslySetInnerHTML={{ __html: aboutData[0].title }}
                  ></h2>

                  {/* Description */}
                  <p
                    className="global-about mt-4"
                    dangerouslySetInnerHTML={{ __html: aboutData[0].description }}
                  ></p>
                </div>
              </Grid>
              <Grid item xs={12} md={5} lg={5} sm={12}>
                <div>
                  <img
                    src={aboutData[0].image}
                    alt="Doctors Image"
                    className="hospital-about-img"
                  />
                </div>
              </Grid>
            </Grid>
          ))}
      </div>

      <div ref={healthRef} className="health-aspirations-section">
        <h2 className="vison-h2 commit-sec text-center">
          We are committed to delivering your health aspirations
        </h2>
        {healthLoading ? (
          <Shimmer style={{ height: "150px", marginBottom: "20px" }} />
        ) : (
          healthAspirationsData && (
            <Grid container spacing={3} className="commit-sec">
              {/* First Column */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aspirations-text">
                  {/* Title */}
                  <h2
                    className="card-header card-asp-title"
                    dangerouslySetInnerHTML={{
                      __html: healthAspirationsData[0]?.title,
                    }}
                  ></h2>
                  {/* Description */}
                  <div className="pt-3">
                    <p className="vision-about"
                      dangerouslySetInnerHTML={{
                        __html: healthAspirationsData[0]?.description,
                      }}>
                    </p>
                  </div>
                </div>
              </Grid>

              {/* Second Column */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aspirations-text">
                  {/* Title */}
                  <h2
                    className="card-header card-asp-title"
                    dangerouslySetInnerHTML={{
                      __html: healthAspirationsData[1]?.title,
                    }}
                  ></h2>
                  {/* Description */}
                  <div className="pt-3">
                    <p className="vision-about"
                      dangerouslySetInnerHTML={{
                        __html: healthAspirationsData[1]?.description,
                      }}
                    ></p>
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        )}
      </div>

      <div ref={visionRef} className="commit-sec" style={{ paddingTop: "0px" }}>
        {visionLoading ? (
          <Shimmer style={{ height: "150px", marginBottom: "20px" }} />
        ) : (
          visionMissionData && (
            <Grid container spacing={3}>
              {/* Vision Section */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="about-vision">
                  <div className="mask-about">
                    <img className="mask-imgeq" src={images.maskimg} alt="" />
                    <div className="vison-about-1">
                      {/* Vision Title */}
                      <h2>{visionMissionData[0]?.title}</h2>
                      {/* Vision Description */}
                      <p
                        className="Empowering"
                        dangerouslySetInnerHTML={{
                          __html: visionMissionData[0]?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </Grid>

              {/* Mission Section */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="about-vision">
                  <div className="mask-about">
                    <img className="mask-imgeq" src={images.union} alt="" />
                    <div className="vison-about-1">
                      {/* Mission Title */}
                      <h2>{visionMissionData[1]?.title}</h2>
                      {/* Mission Description */}
                      <p
                        className="Empowering"
                        dangerouslySetInnerHTML={{
                          __html: visionMissionData[1]?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        )}
      </div>

      <div ref={coreRef} className="mt-5 commit-sec">
        <h2 className="vison-h2 text-center">Our Core Values</h2>
        <p className="vision-1p text-center mb-4">
          At Global Hospital, our core values drive everything we do, ensuring the highest standards of care and compassion.
        </p>

        {coreLoading ? (
          <Shimmer style={{ height: "150px", marginBottom: "20px" }} />
        ) : (
          coreValueData && (
            <Grid container spacing={2} justifyContent="center">
              {coreValueData.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={2.4}>
                  <Card className="specialties-card2">
                    <CardMedia
                      component="img"
                      image={item.image} // Dynamic image from API
                      alt={item.title}
                      className="special-image-about"
                    />
                    <CardContent>
                      {/* Title */}
                      <Typography variant="h6" className="specialty-heading">
                        {item.title}
                      </Typography>
                      {/* Description */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="pt-2"
                      >
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )
        )}
      </div>

      <div ref={commitmentRef} className="commitment-sec mt-5">
        <div className="out-commit-sec">
          <Grid container spacing={5}>
            <Grid item md={4} xs={12} sm={12}>
              <h1>Our Commitment</h1>
              {/* <p className="mt-5">
                We believe trust is the cornerstone of all our relationships and it starts with honoring our commitments. With our people, size,
                reach, and relentless pursuit of excellence, we commit to making a difference, one patient, one family, one touch at a time.
              </p> */}
            </Grid>
            <Grid item md={8} xs={12} sm={12}>
              {commitmentLoading ? (
                <Shimmer style={{ height: "150px", marginBottom: "20px" }} />
              ) : (
                <Grid container spacing={3}>
                  {ourCommitmentData
                    ?.sort((a, b) => a.id - b.id) // Sort items by id
                    .map((item) => (
                      <Grid key={item.id} item md={4} xs={12} sm={6}>
                        <Box className="patients-box">
                          <img src={item.image} alt={item.title} />
                          <h5 className="mt-4">
                            <b>{item.title}</b>
                          </h5>
                          <p className="mt-3 patient-box-p" style={{ lineHeight: "23px" }}>
                            {item.description}
                          </p>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </div>


      {aboutData && aboutData[1] && (
        <div className="commit-sec mt-5">
          <Grid container spacing={2}>
            {/* Text Content */}
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              style={{ marginTop: "100px" }}
              className="about-title"
            >
              <div className="about-text">
                {/* Title */}
                <h2
                  className="Experience-about-1"
                  dangerouslySetInnerHTML={{ __html: aboutData[1]?.title }}
                ></h2>

                {/* Description */}
                <div
                  className="global-about mt-5"
                  dangerouslySetInnerHTML={{
                    __html: aboutData[1]?.description,
                  }}
                ></div>
              </div>
            </Grid>

            {/* Image */}
            <Grid item md={6} sm={12} xs={12}>
              <img
                className="hospital-about-img"
                src={aboutData[1]?.image} // Dynamic image from the second index
                alt="About Section"
              />
            </Grid>
          </Grid>
        </div>
      )}

      {aboutData && aboutData[2] && (
        <div className="commit-sec mt-5">
          <Grid container spacing={2}>
            {/* Image */}
            <Grid item md={6} sm={12} xs={12}>
              <img
                src={aboutData[2]?.image} // Dynamic image from the third index
                alt="Our Presence"
                className="hospital-about-img"
              />
            </Grid>

            {/* Text Content */}
            <Grid item md={6} sm={12} xs={12}>
              <div className="about-text">
                {/* Title */}
                <h2
                  className="Experience-about-1"
                  dangerouslySetInnerHTML={{ __html: aboutData[2]?.title }}
                ></h2>

                {/* Description */}
                <div
                  className="global-about mt-5"
                  dangerouslySetInnerHTML={{ __html: aboutData[2]?.description }}
                ></div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}

      {(aboutData && (aboutData[3] || aboutData[4])) && (
        <div className="Review-car">
          <div className="standard-car">
            {aboutData[3] && (
              <Grid container>
                <Grid item md={6} xs={12} sm={12}>
                  <h2
                    dangerouslySetInnerHTML={{ __html: aboutData[3]?.title }}
                  ></h2>
                  <p
                    className="mt-4 dummy-text"
                    dangerouslySetInnerHTML={{ __html: aboutData[3]?.description }}
                  ></p>
                </Grid>
                <Grid item md={6} xs={12} sm={12}>
                  <img
                    src={aboutData[3]?.image}
                    alt=""
                    className="female-doctor"
                    style={{ position: "absolute", top: "-28%", width: "550px" }}
                  />
                </Grid>
              </Grid>
            )}
            {aboutData && aboutData[4] && (
              <div>
                <h2
                  className="mt-4"
                  dangerouslySetInnerHTML={{ __html: aboutData[4]?.title }}
                ></h2>
                <Grid container spacing={5} className="mt-4">
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="review-mark mt-4">
                      <img src={images.quote} alt="" />
                      <h6 className="mt-2 review-h2">
                        Champions of Holistic Healthcare & Management,Serving Both
                        India & the Global Community
                      </h6>
                      <div style={{ textAlign: "end" }}>
                        <img src={images.quote1} alt="" />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <p
                      className="dummy-text"
                      dangerouslySetInnerHTML={{ __html: aboutData[4]?.description }}
                    ></p>
                  </Grid>
                </Grid>
              </div>
            )}


          </div>
        </div>
      )}

      <div ref={qualityRef} className="specialtiessa-container">
        <h3 className="text-center mb-5">Quality Standards</h3>
        <div className="main-about-catogory">
          <Grid container spacing={2} justifyContent="center">
            {qualityLoading ? (
              // Shimmer placeholders while loading
              [...Array(5)].map((_, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={2.4}>
                  <Box className="specialties-box-about">
                    <div className="shimmer" style={{ width: "100%", height: "150px" }} />
                    <Typography
                      variant="h6"
                      className="specialty-heading shimmer-text"
                      style={{
                        height: "20px",
                        width: "70%",
                        marginTop: "10px",
                        background: "#e0e0e0",
                      }}
                    ></Typography>
                  </Box>
                </Grid>
              ))
            ) : (
              // Render actual data after loading
              qualityStandardsData
                ?.sort((a, b) => a.id - b.id) // Sort data by id
                .map((item) => (
                  <Grid key={item.id} item xs={12} sm={6} md={4} lg={2.4}>
                    <Box className="specialties-box-about">
                      <img
                        src={item.image} // Dynamic image from API
                        alt={item.title}
                        className="special-image-surgery"
                      />
                      <Typography variant="h6" className="specialty-heading">
                        {item.title} {/* Dynamic title from API */}
                      </Typography>
                    </Box>
                  </Grid>
                ))
            )}
          </Grid>
        </div>
      </div>

      <Booking
        headerData={headerData}
        footerData={footerData}
      />
    </>
  );
};

export default AboutUs;

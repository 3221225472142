import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";
import doctorImage from "../../assets/images/404/doctor.png"; // Path to doctor illustration
import ambulanceImage from "../../assets/images/404/ambulance1.png"; // Path to ambulance illustration

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      {/* Left Section with Doctor Image */}
      <div className="left-section">
        <img src={doctorImage} alt="Doctor" className="doctor-illustration" />
      </div>

      {/* Center Section with Error Content */}
      <div className="error-content">
        <h1 className="error-title">404!</h1>
        <p className="error-subtitle">Oops!</p>
        <p className="error-description">
          We can't seem to find the page you are looking for.
        </p>
        <div className="error-actions">
          <button className="error-btn" onClick={() => navigate("/")}>
            Go Back Home
          </button>
          <button className="error-btn" onClick={() => navigate("/contact")}>
            Contact Us
          </button>
        </div>
      </div>

      {/* Right Section with Ambulance */}
      <div className="right-section">
        <img
          src={ambulanceImage}
          alt="Ambulance"
          className="ambulance-illustration"
        />
      </div>
    </div>
  );
};

export default NotFound;

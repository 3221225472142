import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
  Grid2,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Pagination,
  Chip,
} from "@mui/material";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import {
  images,
  doctorImages,
  healthPackages,
  banner,
} from "../../Config/images";
import "../DoctorList/DoctorList.css";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import SearchBar from "material-ui-search-bar";
import Booking from "../../componant/Booking/Booking";
import { getDoctorInfo } from "../../Services/services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppointmentPopUp from "../../componant/AppointmentPopUp/AppointmentPopUp";
import "../ViewProfile/ViewProfile.css";

const ViewProfile = ({ headerData, footerData }) => {
  const { id } = useParams();
  const topRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [doctorsDetails, setDoctorsDetails] = useState([]);
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Doctor Details",
      url: `/doctor-list`,
    },
    {
      label: doctorsDetails && doctorsDetails.name,
      url: `/view-profile/${id}`,
    },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const getDoctorsDetails = async () => {
    setLoading(true);
    try {
      const response = await getDoctorInfo(id);
      if (response.data.status === true) {
        setDoctorsDetails(response.data.responseBody);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getDoctorsDetails();
  }, []);
  const [showFullText, setShowFullText] = useState(false);
  const [showAllExperience, setShowAllExperience] = useState(false);
  const [showAllAchievements, setShowAllAchievements] = useState(false);
  const [showAllQualifications, setShowAllQualifications] = useState(false);
  const [showAllPublications, setShowAllPublications] = useState(false);

  useEffect(() => {
    const topElement = topRef.current;

    if (topElement) {
      const navbarHeight = 800; // Replace with your navbar's height in pixels
      const topPosition = topElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    // Scroll based on navigation intent
    if (location.state?.section === "book-appointment") {
      setOpenPopup(true);
      // remove the section from location.state without navigation
      navigate(location.pathname, { replace: true });
    }
  }, [location.state]);

  return (
    <>
      <div>
        {/* <div className="header-image" ref={topRef}>
          <div className="absolute-image-container">
            <img
              src={banner.doctorDetailsBanner}
              alt="Doctors"
              className="absolute-image"
            />
            <div className="image-text-about">
              <h1 className="text-about">Doctor Details</h1>
              <p className="text-health">Meet our specialist</p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div> */}
        <div className="content-image" ref={topRef}>
          <div className="image-container">
            <img
              className="background-image"
              src={banner.doctorDetailsBanner}
              alt="Banner"
            />
            <div className="text-container">
              <h1 className="text-title">Doctor Details</h1>
              <p className="text-paragraph">Meet our specialist</p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <HomeBooking noMargin footerData={footerData} />
        </div>
        <div className="commit-sec mb-5">
          <div className="doctor-title mt-3">Doctor Profile</div>
          <div className="pt-5">
            <div
              className="d-flex gap-5 doct-pro-sec"
            // style={{ flexWrap: "wrap" }}
            >
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12} lg={4} style={{ textAlign: "center" }}>
                    <div>
                      {!loading ? (
                        <img
                          className="view-profile-img"
                          src={doctorsDetails?.image}
                          alt="doctor-img"
                        />
                      ) : (
                        <div className="shimmer-profile">
                          <div className="shimmer-profile-image"></div>
                        </div>)
                      }
                    </div>
                    <button
                      variant="contained"
                      size="small"
                      className="book-appoinment-btn-view mt-3 mx-4"
                      onClick={() => setOpenPopup(true)}
                    >
                      Book An Appointment
                    </button>

                  </Grid>
                  <Grid item md={12} xs={12} lg={8}>
                    <Box
                      // sx={{
                      //   width: "650px",
                      // }}
                      className="profile-box"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                            className="profile-name"
                          >
                            Name:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={9}
                          lg={9}
                          className="doct-pro"
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                            className="doct-name"
                          >
                            {doctorsDetails?.name}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "gray" }}>
                            {doctorsDetails.type}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                            className="profile-name"
                          >
                            Speciality:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={9}
                          lg={9}
                          className="doct-pro"
                        >
                          {!loading ? (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}
                            >
                              {doctorsDetails?.specialties
                                ?.split(",")
                                .map((speciality, index) => (
                                  <Chip
                                    key={index}
                                    label={speciality.trim()}
                                    className="chip-label"
                                    sx={{
                                      borderRadius: "5px",
                                    }}
                                  />
                                ))}
                            </Box>
                          ) : (
                            <div className="shimmer-chip" />
                          )}

                        </Grid>

                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                            className="profile-name"
                          >
                            Qualification:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={9}
                          lg={9}
                          className="doct-pro"
                        >
                          {!loading ? (
                            <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                              {doctorsDetails?.qualifications?.map(
                                (qualification, index) => (
                                  <Chip
                                    key={index}
                                    label={qualification.qualification_name}
                                    className="chip-label"
                                    sx={{ borderRadius: "5px", marginRight: "8px" }}
                                  />
                                )
                              )}
                            </Box>
                          ) : (
                            <div className="shimmer-chip" />
                          )}

                        </Grid>

                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                            className="profile-name"
                          >
                            Experience:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={9}
                          lg={9}
                          className="doct-pro"
                        >
                          {!loading ? (
                            <Chip
                              label={doctorsDetails.experience}
                              style={{ fontWeight: 700 }}
                              className="chip-label"
                              sx={{ borderRadius: "5px" }}
                            />
                          ) : (<div className="shimmer-chip" />)
                          }
                        </Grid>

                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                            className="profile-name"
                          >
                            Gender:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={9}
                          lg={9}
                          className="doct-pro"
                        >
                          {!loading ? (
                            <Chip
                              label={doctorsDetails?.gender?.charAt(0).toUpperCase() + doctorsDetails?.gender?.slice(1)}
                              className="chip-label"
                              sx={{ borderRadius: "5px" }}
                            />) : (<div className="shimmer-chip" />)
                          }
                        </Grid>

                        {/* Language Spoken */}
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                            className="profile-name"
                          >
                            Language Spoken:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={9}
                          lg={9}
                          className="doct-pro"
                        >
                          {!loading ? (
                            <Box sx={{ display: "flex", gap: "8px" }}>
                              {doctorsDetails?.languages?.map((item, index) => (
                                <Chip
                                  key={index}
                                  label={item.language}
                                  className="chip-label"
                                  sx={{ borderRadius: "5px", marginRight: "8px" }}
                                />
                              ))}
                            </Box>
                          ) : (<div className="shimmer-chip" />)
                          }
                        </Grid>

                        {/* Location */}
                        {/* <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Location:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9} className="doct-pro">
                      <Chip
                        label={doctorsDetails?.hospitals}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid> */}

                        {/* Consultation Charges */}
                        {/* <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Consultation Charges:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9} className="doct-pro">
                      <Chip
                        label={doctorsDetails?.consultation_charges}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid> */}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
          {/* About Section */}
          {doctorsDetails?.about && (
            <div className="about-section mt-4">
              <h2 className="about-heading">About</h2>
              <p className="about-description">
                {doctorsDetails?.about?.length > 200 // Check if the text is longer than 200 characters
                  ? showFullText
                    ? doctorsDetails?.about // Show full text
                    : `${doctorsDetails?.about.substring(0, 200)}...` // Show limited text
                  : doctorsDetails?.about}{" "}
              </p>
              {doctorsDetails?.about?.length > 200 && ( // Only show "More/Less" if the text is longer than 200 characters
                <p
                  className="about-more"
                  onClick={() => setShowFullText(!showFullText)} // Toggle the state
                >
                  {showFullText ? "+ Less" : "+ More"}{" "}
                </p>
              )}
            </div>
          )}
          {/* Experience Section */}
          {doctorsDetails?.experience_details?.length > 0 && (
            <div className="about-section">
              <h2 className="about-heading">Experience</h2>
              <div className="about-description">
                {doctorsDetails?.experience_details
                  ?.slice(
                    0,
                    showAllExperience
                      ? doctorsDetails.experience_details.length
                      : 2
                  )
                  .map((experience, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        src={healthPackages.checkIcon}
                        alt=""
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "3px",
                        }}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p style={{ marginBottom: "2px", fontWeight: 700 }}>
                          {experience.time}
                        </p>
                        <p style={{ margin: "0px" }}>{experience.title}</p>
                      </div>
                    </div>
                  ))}
              </div>
              {doctorsDetails?.experience_details?.length > 2 && ( // Only show "More/Less" if there are more than 2 items
                <div
                  className="about-more"
                  onClick={() => setShowAllExperience(!showAllExperience)} // Toggle the state
                >
                  {showAllExperience ? "+ Less" : "+ More"}{" "}
                </div>
              )}
            </div>
          )}
          {/* Achievements Section */}
          {doctorsDetails?.achievements?.some((achievement) => achievement) && (
            <div className="about-section">
              <h2 className="about-heading">Achievements</h2>
              <div className="about-description">
                {doctorsDetails?.achievements
                  ?.filter((achievement) => achievement)
                  .slice(
                    0,
                    showAllAchievements ? doctorsDetails.achievements.length : 2
                  )
                  .map((achievement, index) => (
                    <div key={index} style={{ display: "flex", gap: "10px" }}>
                      <img
                        src={healthPackages.checkIcon}
                        alt=""
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "3px",
                        }}
                      />
                      <p>{achievement}</p>
                    </div>
                  ))}
              </div>
              {doctorsDetails?.achievements?.filter(
                (achievement) => achievement
              ).length > 2 && (
                  <div
                    className="about-more"
                    onClick={() => setShowAllAchievements(!showAllAchievements)}
                  >
                    {showAllAchievements ? "+ Less" : "+ More"}
                  </div>
                )}
            </div>
          )}

          {/* Qualifications Section */}
          {doctorsDetails?.qualifications?.length > 0 && (
            <div className="about-section">
              <h2 className="about-heading">Qualifications</h2>
              <div className="about-description">
                {doctorsDetails?.qualifications
                  ?.slice(
                    0,
                    showAllQualifications
                      ? doctorsDetails.qualifications.length
                      : 2
                  )
                  .map((qualification, index) => (
                    <div key={index} style={{ display: "flex", gap: "10px" }}>
                      <img
                        src={healthPackages.checkIcon}
                        alt=""
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "3px",
                        }}
                      />
                      <p>{qualification.qualification_name}</p>
                    </div>
                  ))}
              </div>
              {doctorsDetails?.qualifications?.length > 2 && ( // Only show "More/Less" if there are more than 2 items
                <div
                  className="about-more"
                  onClick={() =>
                    setShowAllQualifications(!showAllQualifications)
                  } // Toggle the state
                >
                  {showAllQualifications ? "+ Less" : "+ More"}{" "}
                </div>
              )}
            </div>
          )}

          {/* Publications Section */}
          {doctorsDetails?.publications?.some((publication) => publication) && (
            <div className="about-section">
              <h2 className="about-heading">Publications</h2>
              <div className="about-description">
                {doctorsDetails?.publications
                  ?.filter((publication) => publication)
                  .slice(
                    0,
                    showAllPublications ? doctorsDetails.publications.length : 2
                  )
                  .map((publication, index) => (
                    <div key={index} style={{ display: "flex", gap: "10px" }}>
                      <img
                        src={healthPackages.checkIcon}
                        alt=""
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "3px",
                        }}
                      />
                      <p>{publication}</p>
                    </div>
                  ))}
              </div>
              {doctorsDetails?.publications?.filter(
                (publication) => publication
              ).length > 2 && (
                  <div
                    className="about-more"
                    onClick={() => setShowAllPublications(!showAllPublications)}
                  >
                    {showAllPublications ? "+ Less" : "+ More"}
                  </div>
                )}
            </div>
          )}

          <AppointmentPopUp
            open={openPopup}
            onClose={() => setOpenPopup(false)}
            doctor_id={id}
          />
        </div>
        <div>
          <Booking headerData={headerData} footerData={footerData} />
        </div>
      </div>
    </>
  );
};

export default ViewProfile;

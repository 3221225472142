import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import { TiSocialLinkedin } from "react-icons/ti";
import "./Header.css";
import { images } from "../../Config/images";

const Header = ({headerData, footerData}) => {
 
  return (
    <>
      <div className="header">
        
          <div className="header-wrap">
            <div>
              <div className="header-left">
                {/* Facebook Link */}
                <div>
                  <a
                   href={headerData?.facebook_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialFacebook className="social-icon" />
                  </a>
                </div>
                {/* Twitter Link */}
                {/* <div>
                  <a
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialTwitter className="social-icon" />
                  </a>
                </div> */}
                <div>
                  <a
                   href={headerData?.youtube_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialYoutube className="social-icon" />
                  </a>
                </div>
                <div>
                  <a
                    href={headerData?.instagram_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialInstagram className="social-icon" />
                  </a>
                </div>

                {/* LinkedIn Link */}
                <div>
                  <a
                    href={headerData?.linkedin_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialLinkedin className="social-icon" />
                  </a>
                </div>
              </div>
            </div>

            <div className="header-right">
              {/* Phone Number */}
              <div className="info-details">
                <div>
                  <img
                    src={images.contact}
                    className="info-icon"
                    alt="contact icon"
                  />
                </div>
                <div>
                  <h6>
                    <a 
                    href={`tel:${footerData?.appointment_number}`}
                    class="anchor">
                      {footerData?.appointment_number}
                      </a>
                  </h6>
                </div>
              </div>
              {/* Email */}
              <div className="info-details">
                <div>
                  <img
                    src={images.email}
                    className="info-icon"
                    alt="email icon"
                  />
                </div>
                <div>
                  <h6 class="anchor">
                    <a 
                    // href= "mailto:info@globalhospital.co" 
                    href={`mailto:${footerData?.email}`}
                    class="anchor">
                      {footerData?.email}
                      </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
       
      </div>
    </>
  );
};

export default Header;

import React, { useState, useEffect, useRef } from "react";
import "../Specialties/Specialties.css";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
  Skeleton,
  // Stack,
} from "@mui/material";
import cardiogram from "../../assets/images/cardiogram.png";
import Stack from "@mui/material/Stack";
import { banner, images } from "../../Config/images";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import { specialitiesIcons, specialitiesIconsNew } from "../../Config/images"; // Importing image assets (if needed)
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { getSpecialtiesList } from "../../Services/services";
import Booking from "../../componant/Booking/Booking";

// const specialties = [
//   { name: "General & Minimal Access Surgery", image: specialitiesIcons.surgeryRoom, alt: "General & Minimal Access Surgery" },
//   { name: "Surgical Gastroenterology", image: specialitiesIcons.gastro, alt: "Surgical Gastroenterology" },
//   { name: "Medical Gastroenterology", image: specialitiesIcons.ctScan, alt: "Medical Gastroenterology" },
//   { name: "General Medicine", image: specialitiesIcons.medicine, alt: "General Medicine" },
//   { name: "Surgical Oncology", image: specialitiesIcons.roboticSurgery, alt: "Surgical Oncology" },
//   { name: "Medical Oncology", image: specialitiesIcons.medical, alt: "Medical Oncology" },
//   { name: "Urosurgery", image: specialitiesIcons.psychology, alt: "Urosurgery" },
//   { name: "Nephrology", image: specialitiesIcons.kidneys, alt: "Nephrology" },
//   { name: "Orthopaedics & Joint Replacement", image: specialitiesIcons.painInJoints, alt: "Orthopaedics & Joint Replacement" },
//   { name: "Neuro & Spine Surgery", image: specialitiesIcons.mentalState, alt: "Neuro & Spine Surgery" },

//   { name: "Anesthesiology", image: specialitiesIconsNew.anesthesiology, alt: "Anesthesiology" },
//   { name: "Aesthetics", image: specialitiesIconsNew.aesthetics, alt: "Aesthetics" },
//   { name: "Audiology & Speech Therapy", image: specialitiesIconsNew.audiologyAndSpeechTherapy, alt: "Audiology & Speech Therapy" },
//   { name: "Cancer Care", image: specialitiesIconsNew.cancer, alt: "Cancer Care" },
//   { name: "Cardiology", image: specialitiesIconsNew.cardiology, alt: "Cardiology" },
//   { name: "Cardiovascular Surgery", image: specialitiesIconsNew.cardiovascularSurgery, alt: "Cardiovascular Surgery" },
//   { name: "Cranio Maxillofacial Surgery", image: specialitiesIconsNew.cranioMaxillofacialSurgery, alt: "Cranio Maxillofacial Surgery" },
//   { name: "Critical Care", image: specialitiesIconsNew.criticalCare, alt: "Critical Care" },
//   { name: "Dental Sciences", image: specialitiesIconsNew.dentalSciences, alt: "Dental Sciences" },
//   { name: "Dermatology & Cosmetology", image: specialitiesIconsNew.dermatologyAndCosmetology, alt: "Dermatology & Cosmetology" },
//   { name: "Diabetology", image: specialitiesIconsNew.diabetology, alt: "Diabetology" },
//   { name: "ENT (Ear, Nose & Throat)", image: specialitiesIconsNew.ent, alt: "ENT" },
//   { name: "Emergency", image: specialitiesIconsNew.ambulance, alt: "Emergency" },
//   { name: "Endocrinology", image: specialitiesIconsNew.endocrinology, alt: "Endocrinology" },
//   { name: "General Medicine", image: specialitiesIconsNew.generalMedicine, alt: "General Medicine" },
//   { name: "General & Minimal Access Surgery", image: specialitiesIconsNew.generalAndMinimalAccessSurgery, alt: "General Surgery" },
//   { name: "HPB Surgery", image: specialitiesIconsNew.hpbSurgery, alt: "HPB Surgery" },
//   { name: "Infectious Disease", image: specialitiesIconsNew.infectiousDisease, alt: "Infectious Disease" },
//   { name: "Interventional Radiology", image: specialitiesIconsNew.interventionalRadiology, alt: "Interventional Radiology" },
//   { name: "Laboratory", image: specialitiesIconsNew.laboratory, alt: "Laboratory" },
//   { name: "Medical Gastroenterology", image: specialitiesIconsNew.surgicalGastroenterology, alt: "Medical Gastroenterology" },
//   { name: "Nephrology", image: specialitiesIconsNew.nephrology, alt: "Nephrology" },
//   { name: "Neuro & Spine Surgery", image: specialitiesIconsNew.neuroAndSpineSurgery, alt: "Neuro & Spine Surgery" },
//   { name: "Neurology", image: specialitiesIconsNew.psychological, alt: "Neurology" }, // Reusing as no separate Neurology icon was mentioned
//   { name: "Nutrition & Dietetics", image: specialitiesIconsNew.nutritionAndDietitics, alt: "Nutrition & Dietetics" },
//   { name: "Obstetrics & Gynaecology", image: specialitiesIconsNew.obstetricsAndGynaecology, alt: "Obstetrics & Gynaecology" },
//   { name: "Ophthalmology", image: specialitiesIconsNew.ophthalmology, alt: "Ophthalmology" },
//   { name: "Orthopaedics & Joint Replacement", image: specialitiesIconsNew.orthopaedicsAndJointReplacement, alt: "Orthopaedics & Joint Replacement" },
//   { name: "Paediatrics", image: specialitiesIconsNew.paediatrics, alt: "Paediatrics" },
//   { name: "Pain Management", image: specialitiesIconsNew.painManagement, alt: "Pain Management" },
//   { name: "Physiotherapy", image: specialitiesIconsNew.physiotherapy, alt: "Physiotherapy" },
//   { name: "Plastic Cosmetic & Reconstructive Surgery", image: specialitiesIconsNew.plasticCosmeticReconstructiveSurgery, alt: "Plastic Surgery" },
//   { name: "Psychiatry", image: specialitiesIconsNew.psychiatry, alt: "Psychiatry" },
//   { name: "Pulmonology & Interventional Pulmonology", image: specialitiesIconsNew.pulmonologyInterventionalPulmonology, alt: "Pulmonology" },
//   { name: "Rheumatology", image: specialitiesIconsNew.rheumatology, alt: "Rheumatology" },
//   { name: "Surgical Gastroenterology", image: specialitiesIconsNew.surgicalGastroenterology2, alt: "Surgical Gastroenterology" },
//   { name: "Surgical Oncology", image: specialitiesIconsNew.surgicalOncology, alt: "Surgical Oncology" },
//   { name: "Urosurgery", image: specialitiesIconsNew.urosurgery, alt: "Urosurgery" },
//   { name: "Urogynaecology", image: specialitiesIconsNew.urogynaecology, alt: "Urogynaecology" },
//   { name: "Vascular & Endovascular Surgery", image: specialitiesIconsNew.vascularAndEndovascularSurgery, alt: "Vascular Surgery" },
// ];


const Specialties = ({ headerData, footerData }) => {
  const navigate = useNavigate();
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Specialist",
      url: "/specialties",
    },
  ];
  const topRef = useRef(null);
  const [specialties, setSpecialties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const getSpecialties = async (page) => {
    try {
      const response = await getSpecialtiesList({ per_page: 20, page });
      if (response.status === 200) {
        setSpecialties(response.data.responseBody.data);
        setTotalPages(response.data.responseBody.last_page);
        setIsLoading(false); // Stop shimmer after response
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false); // Stop shimmer even on failure
    }
  }

  useEffect(() => {
    getSpecialties(currentPage);
  }, [currentPage]);

  // Pagination click handler
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const chunkSize = 6;
  const groupedSpecialties = [];
  for (let i = 0; i < specialties.length; i += chunkSize) {
    groupedSpecialties.push(specialties.slice(i, i + chunkSize));
  }

  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      borderColor: "var(--secondary-color)",
      borderWidth: "1px",
      borderStyle: "solid",
      marginTop: "30px",
    },
    "& .Mui-selected": {
      borderColor: "var(--secondary-color)",
      backgroundColor: "var(--secondary-color)  !important",
      color: "var(--white)",
    },
  }));

  useEffect(() => {
    const topElement = topRef.current;

    if (topElement) {
      const navbarHeight = 800; // Replace with your navbar's height in pixels
      const topPosition = topElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <div>
      {/* <div className="header-image" ref={topRef}>
        <div className="absolute-image-container">
          <img
            src={banner.specialistBanner} // Replace with your image source
            alt="Doctors"
            className="absolute-image"
          />
          <div className="image-text-about">
            <h1 className="text-about">Specialties</h1>
            <p className="text-health">
              Wide range of Specialties <br />
              facilities under one roof
            </p>
            <div className="breadcrumb">
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div> */}

      <div className="content-image" ref={topRef}>
        <div className="image-container">
          <img
            className="background-image"
            src={banner.specialistBanner}
            alt="Banner"
          />
          <div className="text-container">
            <h1 className="text-title">Specialties</h1>
            <p className="text-paragraph">
              Wide range of Specialties <br />
              facilities under one roof
            </p>
            <div className="breadcrumb">
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div>



      <HomeBooking noMargin footerData={footerData} />
      <div className="specialties-section mb-5">

        {/* Header Section */}
        <div className="mt-5 mb-3">
          <h2 className="specialties-title">Key Specialties</h2>
          <div className="healthcare">
            <p className="personalized-text">
              Personalized Healthcare Solutions <br />
              Designed Just For You
            </p>
            {/* <button variant="outlined" className="view-all-btn">
              View All
            </button> */}
          </div>
        </div>

        {/* Grid Section */}

        <div className="mt-4">
          {isLoading ? (
            // Shimmer Effect
            <Grid container spacing={2} justifyContent="center">
              {Array.from({ length: 10 }).map((_, index) => (
                <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                  <Box className="specialties-box">
                    <Skeleton variant="rectangular" height={120} />
                    <Skeleton variant="text" height={30} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              justifyContent="center"
            // key={`group-${groupIndex}`}
            >
              {specialties.map((specialty, index) => (
                <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                  <Box
                    className="specialties-box"
                    onClick={() => navigate(`/health-specialist/${specialty.id}`)}
                  >
                    <img
                      src={specialty.image}
                      alt={specialty.name}
                      className="special-image"
                    />
                    <Typography variant="h6" className="specialty-heading"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2, // Limit to 2 lines
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "200px",
                      }}
                    >
                      {specialty.title}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            /* {groupedSpecialties.map((group, groupIndex) => (
              <Grid
                container
                spacing={3}
                justifyContent="center"
                key={`group-${groupIndex}`}
              >
                {group.map((specialty, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2.4}
                    key={`specialty-${groupIndex}-${index}`}
                    sx={{
                      marginBottom: index % 5 === 4 ? "20px" : "0", // Adds margin after every 5th item
                    }}
                  >
                    <Box className="specialties-box" onClick={() => navigate(`/health-specialist/${specialty.id}`)}>
                      <img
                        src={specialty.image}
                        alt={specialty.alt}
                        className="special-image"
                      />
                      <Typography variant="h6" className="specialty-heading">
                        {specialty.title}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))} */

          )}
        </div>

      </div>

      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center" // Centers the content horizontally
        className="mt-5 mb-5 d-flex pagination-stack"
        sx={{
          padding: "16px", // Optional padding for spacing
          borderRadius: "8px", // Optional rounded corners
        }}
      >
        <CustomPagination
          count={totalPages} // Total number of pages
          page={currentPage} // Current page
          onChange={handlePageChange} // Page change handler
          sx={{
            "& .MuiPaginationItem-ellipsis": {
              color: "#01ADEC", // Apply color to the ellipsis (if any)
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#01ADEC", // Background color when a page is selected
              color: "#fff", // White text when selected
            },
          }}
        />
      </Stack>
      <Booking headerData={headerData} footerData={footerData} />
    </div>
  );
};

export default Specialties;
